.Page_Questions {
    padding-bottom: 70px;
}
.Page_Questions .space_div_icon{
    width: 24px;
}
.Page_Questions .td_question{
    width: 30%;
}
.Page_Questions .td_response{
    width: 70%;
}

@media only screen and (max-width: 580px) {
    .Page_Questions .td_question{
        width: auto;
    }
    .Page_Questions .td_response{
        display: none;
    }
}