.Page_Site_Details {
    padding-bottom: 70px;
}
.Page_Site_Details .show_data_arrow{
    flex-wrap: nowrap;
}
.Page_Site_Details .show_data_card_img{
    flex-wrap: nowrap;
}
.Page_Site_Details .show_data_card_icons{
    flex-wrap: nowrap;
}
.Page_Site_Details .space_top{
    position: relative;
    top: 8px;
}
.Page_Site_Details .div_card_icons{
    width: 20%;
}
.Page_Site_Details .div_card_img{
    width: 40%;
}
.Page_Site_Details .show_page_data .show_data_text{
    padding: 20px 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    min-width: 245px;
}
.Page_Site_Details .show_page_data .div_arrow{
    width: 30%;
}
.Page_Site_Details .type_video{
    width: 150px;
}
.Page_Site_Details .show_page_data .show_data_text .div_input{
    width: 100%;
}
.Page_Site_Details .show_page_data .show_data_text .div_btn_img{
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
}
.Page_Site_Details .show_page_data .show_data_text .div_btn_img .div_input{
    width: auto;
    flex-grow: 1;
}
.Page_Site_Details .show_page_data .show_data_text .div_btn_img .div_input .new_file_add{
    flex-grow: 1;
}

.Page_Site_Details .show_page_data .list_input_data{
    flex-wrap: wrap;
}

@media only screen and (max-width: 640px) {
    .Page_Site_Details .show_page_data .div_arrow {
        width: 100%;
    }
}

@media only screen and (max-width: 580px) {
    .Page_Site_Details .div_add_new_data{
        min-width: 110px;
    }
    .Page_Site_Details .show_page_data .show_data_text .div_btn_img{
        flex-wrap: wrap;
    }
}