.Page_Health_Details {
    padding-bottom: 70px;
}
.Page_Health_Details .date_health{
    width: 152px;
}
.Page_Health_Details .hour_health{
    width: 180px;
}
.Page_Health_Details .show_inf_questions{
    flex-direction: column;
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 16px;
    margin-bottom: 20px;
}
.Page_Health_Details .show_inf_questions:nth-last-child(-n + 1) {
    border-bottom: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.Page_Health_Details .show_inf_questions .div_input{
    width: 100%;
}
.Page_Health_Details .show_inf_questions .textarea{
    resize: none;
    width: calc(100% - 24px) !important;
}
.Page_Health_Details .show_inf_questions .data_text{
    padding: 10px;
    border: 2px solid #ebebeb;
    border-radius: 6px;
}

@media only screen and (max-width: 580px) {
    .Page_Health_Details .div_add_new_data{
        min-width: 110px;
    }
}