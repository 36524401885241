.Page_Users_Details {
    padding-bottom: 70px;
}
.Page_Users_Details .data_select{
    width: 140px;
}
.Page_Users_Details .pass, .Page_Users_Details .dash_user_name {
    width: 120px;
}
.Page_Users_Details .dash_user_email {
    width: 220px;
}
.Page_Users_Details .list_name_page{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}
.Page_Users_Details .div_name_page_access{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    border: 1px solid #2d3c53;
    padding: 4px 10px;
    border-radius: 6px;
}
.Page_Users_Details .input_name_page{
    width: 92px;
    cursor: default;
}

@media only screen and (max-width: 580px) {
    .Page_Users_Details .pass, .Page_Users_Details .dash_user_name{
        width: -webkit-fill-available;
    }
    .Page_Users_Details .div_add_new_data{
        min-width: 110px;
    }
}